import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { GET_FUNCTION_BOOKING_TIME, POST_BOOK_TABLE, POST_GET_DETAIL_TABLE_BOOKING } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';

const ChangePassword = props => {
    let history = useHistory();
    if (!IsModuleAccess('funcation_booking')) {
        history.push("/");
    }
    const [FunctionList, setFunctionList] = useState([
        { id: 'Birthday', value: 'Birthday' },
        { id: 'Engagement', value: 'Engagement' },
        { id: 'Corporate', value: 'Corporate' },
        { id: 'Other', value: 'Other' },
    ]);
    const [isLoader, setLoader] = useState(0);
    const [BookingDetail, setBookingDetail] = useState({ table_booking_id: '', booking_type: 'Functions Booking', date: '', time: '', first_name: '', number_of_people: 1, phone_number: '', email: '', function_type: '', food: '', note: '' });

    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [title, setTitle] = useState('Add Function Enquiry');
    const [startDate, setStartDate] = useState('');


    useEffect(() => {
        async function get_detaile() {
            var date_res_data = await get(GET_FUNCTION_BOOKING_TIME);
            if (date_res_data.status) {
                setDateList(date_res_data.data.date_array);
            }

            if (props.match.params.id) {
                var res_data = await post(POST_GET_DETAIL_TABLE_BOOKING, { table_booking_id: props.match.params.id });
                if (res_data.status) {
                    setStartDate(new Date(res_data.data.detail.date));
                    setBookingDetail(res_data.data.detail);
                    for (var date_value of date_res_data.data.date_array) {
                        if (date_value.date == res_data.data.detail.date) {
                            setTimeList(date_value.time);
                        }
                    }
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'date') {
            for (var date_value of dateList) {
                if (date_value.date == event.target.value) {
                    setTimeList(date_value.time);
                }
            }
            BookingDetail.time = '';
        }
        setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            SetButtonDisabled(true);
            var res_data = await post(POST_BOOK_TABLE, BookingDetail);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/functions-booking");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);

        }
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");

        setTimeList([]);
        for (var date_value of dateList) {
            if (date_value.date == new_date) {
                setTimeList(date_value.time);
            }
        }
        BookingDetail.time = '';
        BookingDetail.date = new_date;

        setBookingDetail(inputs => ({ ...BookingDetail, ['date']: new_date }));
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Name</label>
                                        <input type="text" name="first_name" value={BookingDetail.first_name} onChange={handleInputChange} className="form-control" placeholder="Your First Name" />
                                        {simpleValidator.current.message('first name', BookingDetail.first_name, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Email</label>
                                        <input type="text" name="email" value={BookingDetail.email} onChange={handleInputChange} className="form-control" placeholder="Your Email Address" />
                                        {simpleValidator.current.message('email', BookingDetail.email, 'required|email')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Phone</label>
                                        <input type="text" name="phone_number" value={BookingDetail.phone_number} onChange={handleInputChange} className="form-control" placeholder="Your Phone Number" />
                                        {simpleValidator.current.message('phone number', BookingDetail.phone_number, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Number Of Guests</label>
                                        <select name="number_of_people" value={BookingDetail.number_of_people} onChange={handleInputChange} className="form-control">
                                            {Array.from(Array(40), (e, i) => {
                                                return (<option value={++i}>{i}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Preferred Date</label>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            minDate={new Date()}
                                            maxDate={addMonths(new Date(), 6)}
                                            onChange={setDate}
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                        {/* <select name="date" value={BookingDetail.date} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Date</option>
                                            {dateList && Object.entries(dateList).map(([key, date]) => {
                                                return (<option value={date.date} key={key}>{date.date}</option>);
                                            })}
                                        </select> */}
                                        {simpleValidator.current.message('date', BookingDetail.date, 'required')}
                                    </div>
                                    <div className=" col-sm-4 form-group mb-3">
                                        <label for="">Start Time</label>
                                        <select name="time" value={BookingDetail.time} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Time</option>
                                            {timeList && Object.entries(timeList).map(([key, date]) => {
                                                return (<option value={date.time} key={key}>{date.time}</option>);
                                            })}
                                        </select>
                                        {simpleValidator.current.message('time', BookingDetail.time, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label htmlFor="">Type of Function *</label>
                                        <select name="function_type" value={BookingDetail.function_type} onChange={handleInputChange} className="selectcus form-control">
                                            <option value="">Select Function</option>
                                            {FunctionList && Object.entries(FunctionList).map(([key, value]) => {
                                                return (<option key={key} value={value.id}>{value.value}</option>)
                                            })}
                                        </select>
                                    </div>
                                    {/* <div className="col-sm-4 form-group mb-3">
                                        <label htmlFor="">Food Selection *</label>
                                        <select name="food" value={BookingDetail.food} onChange={handleInputChange} className="selectcus form-control">
                                            <option value="">Select Food</option>
                                            <option value="A la cart">A la cart</option>
                                            <option value="Banquete">Banquete</option>
                                        </select>
                                    </div> */}
                                    <div className="col-sm-4 form-group mb-3">
                                        <label htmlFor="">Additional Information</label>
                                        <textarea name="note" value={BookingDetail.note} onChange={handleInputChange} className="form-control" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-sm-12 text-center mt-4">
                                        <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                        <Link to="/functions-booking" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
